// id: '池子id',
// name: '池子名字',  
// token: '池子代币',
// tokenDescription: '代币描述',
// tokenAddress: '代币ERC20地址',
// earnedToken: '奖励代币',
// earnedTokenAddress: '奖励代币ERC20地址',
// earnContractAddress: '池子合约地址',
// price ： 挖的代币的价格！
// path price: 
import BigNumber from "bignumber.js";
const WETH_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
const YFII_ADDRESS = "0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83";
const DAI_ADDRESS = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
export const pools = [
  {
    id: 'yfii',
    name: 'YFII',  
    token: 'YFII',
    tokenDescription: 'YFII',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/trading-yfii',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_1-yfii%e8%b4%ad%e4%b9%b0%e6%88%96%e5%85%91%e6%8d%a2',
    tokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0xf5a232b1325769E09B303D7292589a2C7AEe2Aa4',
    strategyContractAddress: '0xe9bA312991e76116879b484135D2b86Ea27d0A0f',
    price: 'grap.finance',
    pathprice:["0xc8d2ab2a6fdebc25432e54941cb85b55b9f152db",WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: false,
    yield: 0
  },
  {
    id: 'dai',
    name: 'DAI',  
    token: 'DAI',
    tokenDescription: 'DAI',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/faq?id=dai',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_2-dai%e5%85%91%e6%8d%a2',
    tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0x8FDD31b72Cc18c965E6a7BC85174994e72799732',
    strategyContractAddress: '0x6285FF6AEF7BA5Bddeb67B033dc75f6Da0980191',
    price: 'ZOMBIE.finance',
    pathprice:["0xd55BD2C12B30075b325Bc35aEf0B46363B3818f8",DAI_ADDRESS,WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: false,
    yield: 0
  },
  // {
  //   id: 'usdt',
  //   name: 'USDT',  
  //   token: 'USDT',
  //   tokenDescription: 'USDT',
  //   tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  //   tokenDecimals: 6,
  //   earnedToken: 'YFII',
  //   earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
  //   earnContractAddress: '0x804a3DBb6C1f4c379B3ee985488Ef37C4cBbac5C',
  //   strategyContractAddress: '0x88D89Bc6dF5777ef762D8b7c841b1a4E179dcc83',
  //   price: 'dforce-token',
  //   pathprice:["0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0",WETH_ADDRESS,YFII_ADDRESS],
  //   depositedBalance: 0,
  //   claimAbleBalance: 0,
  //   claimPendingBalance: 0,
  //   claimAbleTokens: 0,
  //   magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
  //   totalStake: 0,
  //   earningsPerShare: 0,
  //   idle: 0,
  //   allowance: 0,
  //   payout: 0,
  //   depositedTime: 0,
  //   isCrv: false
  // },
  {
    id: 'ycrv',
    name: 'YCRV',  
    token: 'yCRV',
    tokenDescription: 'YCRV',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/using-crv?id=how-to-get-ycrv',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_5-ycrv%e5%85%91%e6%8d%a2',
    tokenAddress: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0xD2db1EF55549eCdacb4e7da081216AE96f0Eedcb',
    strategyContractAddress: '0x83612eAc340b967aD380feC9a2D50Ea3FcA1A2cb',
    price: 'curve-dao-token',
    pathprice:["0xd533a949740bb3306d119cc777fa900ba034cd52",WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: false,
    isYFII: true,
    crvGauge:"0xFA712EE4788C042e2B7BB55E6cb8ec569C4530c1",//只有是crv的才需要
    yield: 0
  },
  {
    id: 'ccrv',
    name: 'curve.fi cDAI+cUSDC',  
    token: 'cCrv',
    tokenDescription: 'curve.fi cDAI+cUSDC',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/using-crv?id=how-to-get-ccrv',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_7-ccrv%e5%85%91%e6%8d%a2',
    tokenAddress: '0x845838DF265Dcd2c412A1Dc9e959c7d08537f8a2',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0xf811c062D14fdF9Fda95D6A2C54e137afE80De45',
    strategyContractAddress: '0xEfb684AB29371e701CCe3CA9e3FD8f5E33042eee',
    price: 'curve-dao-token',
    pathprice:["0xd533a949740bb3306d119cc777fa900ba034cd52",WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: true,
    crvGauge:"0x7ca5b0a2910B33e9759DC7dDB0413949071D7575",
    yield: 0
  },
  {
    id: 'weth',
    name: 'WETH',  
    token: 'WETH',
    tokenDescription: 'WETH',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/faq?id=weth',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_3-weth%e5%85%91%e6%8d%a2',
    tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0xf693705e79ccc8707D3FcB4D89381CaC28e45a22',
    strategyContractAddress: '0x602ec22B362B0E8ae658D18f4435fE8c5c23cA0C',
    price: 'grap.finance',
    pathprice:["0xc8d2ab2a6fdebc25432e54941cb85b55b9f152db",WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: false,
    yield: 0
  },
  {
    id: 'link',
    name: 'LINK',  
    token: 'LINK',
    tokenDescription: 'LINK',
    tokenDescriptionUrl: 'https://docs.yfii.finance/#/faq?id=link',
    tokenDescriptionUrl2: 'https://docs.yfii.finance/#/zh-cn/buy-tokens?id=_4-link%e8%b4%ad%e4%b9%b0%e6%88%96%e5%85%91%e6%8d%a2',
    tokenAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    tokenDecimals: 18,
    earnedToken: 'YFII',
    earnedTokenAddress: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    earnContractAddress: '0xCda9230923FCb25e26a20D7D9D12e1744405C9fC',
    strategyContractAddress: '0x780c2450632ecb4be69DA859987Be4875545E90b',
    price: 'grap.finance',
    pathprice:["0xc8d2ab2a6fdebc25432e54941cb85b55b9f152db",WETH_ADDRESS,YFII_ADDRESS],
    depositedBalance: 0,
    claimAbleBalance: 0,
    claimPendingBalance: 0,
    claimAbleTokens: 0,
    magnitude: new BigNumber(10).exponentiatedBy(40).toNumber(),
    totalStake: 0,
    earningsPerShare: 0,
    idle: 0,
    allowance: 0,
    payout: 0,
    depositedTime: 0,
    isCrv: false,
    yield: 0
  }
]